import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// css文件
import "./assets/css/index.scss"
// element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// axios
import { axios } from './request/api.js'
// 表格分页
import Table from '@/components/Table'
// 拖拽
import elDrag from '@/utils/el-drag-dialog'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(elDrag)
Vue.component('Table', Table)
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
