<template>
    <div class="el-page-table">
        <el-table :data="data" :height="height" :max-height="maxHeight" :stripe="stripe" :border="border"
            @selection-change="handleSelectionChange" @expand-change="handleExpandChange" @select-all="handleSelectAll"
            style="width: 100%" :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }">
            <slot></slot>
        </el-table>
        <el-pagination class="page" @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize" :layout="layout" :total="total">
        </el-pagination>
    </div>
</template>
  
<script>
export default {
    name: 'ElPageTable',
    props: {
        data: {
            default() {
                return [];
            },
            type: Array
        },
        height: [String, Number],
        maxHeight: [String, Number],
        stripe: {
            default() {
                return true;
            },
            type: Boolean
        },
        border: {
            default() {
                return true;
            },
            type: Boolean
        },
        currentPage: {
            default() {
                return 1;
            },
            type: Number
        },
        pageSizes: {
            default() {
                return [10, 50, 200, 1000];
            },
            type: Array
        },
        pageSize: {
            default() {
                return 10;
            },
            type: Number
        },
        layout: {
            default() {
                return 'total, sizes, prev, pager, next, jumper';
            },
            type: String
        },
        total: {
            default() {
                return 0;
            },
            type: Number
        }
    },
    data: function () {
        return {
        };
    },
    methods: {
        handleExpandChange(val) {
            this.$emit('expand-change', val);
        },
        handleSelectAll(val) {
            this.$emit('select-all', val);
        },
        handleSelectionChange(val) {
            this.$emit('selection-change', val);
        },
        handleSizeChange(val) {
            this.$emit('size-change', val);
        },
        handleCurrentChange(val) {
            this.$emit('current-change', val);
        }
    },
    mounted() {

    }
};
</script>
  
<style lang="scss" scoped>
.page {
    margin-top: 20px;
    text-align: right;
}
</style>
  