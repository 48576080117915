import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
	path: '/',
	redirect: '/login',
},
{
	name: "login",
	path: "/login",
	component: () => import('../views/login/index.vue'),
},
{
	name: 'index',
	path: '/index',
	component: () => import('../views/index.vue'),
	children: [{
		path: '/dataAnalysis',
		name: 'dataAnalysis',
		component: () => import('../views/dataAnalysis/index.vue')
	},
	{
		path: '/warehouseClassify',
		name: 'warehouseClassify',
		component: () => import('../views/warehouse/warehouseClassify.vue')
	},
	{
		path: '/warehouseData',
		name: 'warehouseData',
		component: () => import('../views/warehouse/warehouseData.vue')
	},
	{
		path: '/commodityClassify',
		name: 'commodityClassify',
		component: () => import('../views/commodity/commodityClassify.vue')
	},
	{
		path: '/commodityData',
		name: 'commodityData',
		component: () => import('../views/commodity/commodityData.vue')
	},
	{
		path: '/stock',
		name: 'stock',
		component: () => import('../views/commodity/stock.vue')
	},
	{
		path: '/supplierManage',
		name: 'supplierManage',
		component: () => import('../views/supplier/supplierManage.vue')
	},
	{
		path: '/deliveryRoute',
		name: 'deliveryRoute',
		component: () => import('../views/driverRoute/deliveryRoute.vue')
	},
	{
		path: '/driverManagement',
		name: 'driverManagement',
		component: () => import('../views/driverRoute/driverManagement.vue')
	},
	{
		path: '/customerGroup',
		name: 'customerGroup',
		component: () => import('../views/customer/customerGroup.vue')
	},
	{
		path: '/customerData',
		name: 'customerData',
		component: () => import('../views/customer/customerData.vue')
	},
	{
		path: '/blacklist',
		name: 'blacklist',
		component: () => import('../views/customer/blacklist.vue')
	},
	{
		path: '/cooperationApply',
		name: 'cooperationApply',
		component: () => import('../views/customer/cooperationApply.vue')
	},
	{
		path: '/salesBilling',
		name: 'salesBilling',
		component: () => import('../views/salesManagement/salesBilling.vue')
	},
	{
		path: '/orderList',
		name: 'orderList',
		component: () => import('../views/salesManagement/orderList.vue')
	},
	{
		path: '/orderListXyf',
		name: 'orderListXyf',
		component: () => import('../views/salesManagement/orderListXyf.vue')
	},
	{
		path: '/orderListAddress',
		name: 'orderListAddress',
		component: () => import('../views/salesManagement/orderListAddress.vue')
	},
	{
		path: '/afterSales',
		name: 'afterSales',
		component: () => import('../views/salesManagement/afterSales.vue')
	},
	{
		path: '/salesSummary',
		name: 'salesSummary',
		component: () => import('../views/salesManagement/salesSummary.vue')
	},
	{
		path: '/preDeliveryNote',
		name: 'preDeliveryNote',
		component: () => import('../views/preDelivery/preDeliveryNote.vue')
	},
	{
		path: '/preDeliveryNoteAddress',
		name: 'preDeliveryNoteAddress',
		component: () => import('../views/preDelivery/preDeliveryNoteAddress.vue')
	},
	{
		path: '/preDeliveryNoteGoods',
		name: 'preDeliveryNoteGoods',
		component: () => import('../views/preDelivery/preDeliveryNoteGoods.vue')
	},
	{
		path: '/preDeliverySummary',
		name: 'preDeliverySummary',
		component: () => import('../views/preDelivery/preDeliverySummary.vue')
	},
	{
		path: '/preDeliverySummaryDay',
		name: 'preDeliverySummaryDay',
		component: () => import('../views/preDelivery/preDeliverySummaryDay.vue')
	},
	{
		path: '/preDeliveryCheck',
		name: 'preDeliveryCheck',
		component: () => import('../views/preDelivery/preDeliveryCheck.vue')
	},
	{
		path: '/restDaySet',
		name: 'restDaySet',
		component: () => import('../views/preDelivery/restDaySet.vue')
	},
	{
		path: '/warehouseManagement',
		name: 'warehouseManagement',
		component: () => import('../views/rkd/warehouseManagement.vue')
	},
	{
		path: '/warehouseReceipt',
		name: 'warehouseReceipt',
		component: () => import('../views/rkd/warehouseReceipt.vue')
	},
	{
		path: '/warehouseSummary',
		name: 'warehouseSummary',
		component: () => import('../views/rkd/warehouseSummary.vue')
	},
	{
		path: '/dayIncomeSummary',
		name: 'dayIncomeSummary',
		component: () => import('../views/financeManage/dayIncomeSummary.vue')
	},
	{
		path: '/monthIncomeSummary',
		name: 'monthIncomeSummary',
		component: () => import('../views/financeManage/monthIncomeSummary.vue')
	},
	{
		path: '/cutTimeSet',
		name: 'cutTimeSet',
		component: () => import('../views/salesManagement/cutTimeSet.vue')
	}
	]
},
]

const router = new VueRouter({
	routes
})

export default router